import React, { useState, useEffect } from "react";
import Layout from "../components/layout/index";
import HeroImageSection from "../components/blogs/heroImageSection";
import BlogCard from "../components/blogs/blogCard";
// import BlogSidebar from "../components/blogs/blogSideBar"
// import HomeSection2 from "../components/home/homeSection2"
// import HomeSection3 from "../components/home/homeSection3"
// import HomeSection4 from "../components/home/homeSection4"
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, TextField, Typography, useMediaQuery } from "@material-ui/core";
import { Search, Close } from "@material-ui/icons";
import CustomButton from "../components/common/button";
import { graphql } from "gatsby";
import * as JsSearch from "js-search";
export default function Blog({ data }) {
  const classes = useStyles();
  const theme = useTheme();
  const [filteredData, setFilteredData] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const [allData, setAllData] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  var search = new JsSearch.Search("title");
  search.addIndex("description");
  search.addIndex("title");

  const add = data?.allStrapiBlogs?.edges?.map((item) =>
    search.addDocuments([item?.node])
  );


  const handleSearch = (e) => {
    if (e.target.value !== "") {
      let result = search.search(e.target.value);

      setFilteredData(result);
    } else {
      setFilteredData(allData);
    }
  };
  useEffect(() => {
    if (data) {
      let array = [];
      let obj = data?.allStrapiBlogs?.edges?.map((item) => {
        return array.push(item.node);
      });
      setFilteredData(array);
      setAllData(array);
    }
  }, [data]);
  const seoData = {
    title: data?.strapiBlogPage?.seo_title,
    description: data?.strapiBlogPage?.seo_description,
    image: data?.strapiBlogPage?.image?.publicURL,
    keyword: data?.strapiBlogPage?.seo_tags,
    url: "/blogs"
  };

  return (
    <Layout seoData={seoData}>
      {/* <Header /> */}
      <Grid container justify="center" style={{ backgroundColor: "#FBFBFB" }}>
        {/* <Grid item xs={12}>
          <HeroImageSection data={data?.strapiBlogPage} />
        </Grid> */}

        <Grid
          container
          style={{
            marginTop: "2%",
            padding: isMobile
              ? "0px 5px"
              : showSearch
              ? "45px 30px 0px"
              : "55px 30px 10px",
            // padding: isMobile ? "0px 5px" : "20px 30px",
          }}
          id="content"
        >
          <Grid item xs={2} />

          <Grid item xs={8}>
            <Typography
             variant="h1"
              style={{ fontSize: isMobile && "24px" }}
              className={classes.heading}
            >
              {data?.strapiBlogPage?.title}
            </Typography>
          </Grid>
        {/*   <Grid
            item
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {!showSearch && (
              <Search
                className={classes.searchIcon}
                onClick={() => setShowSearch(!showSearch)}
              />
            )}
          </Grid> */}
          <Grid container justify="center">
            <Grid
              item
              xs={12}
              xs={12}
              md={6}
              container
              justify="center"
              spacing={isMobile ? 1 : 2}
              style={{
                padding: isMobile ? "20px 5px" : "20px 30px",
              }}
            >

                  <Grid item xs={8} sm={8} md={9} lg={9}>
                    <TextField
                      className={classes.searchField}
                      type="Search"
                      style={{ width: "100%" }}
                      placeholder="Search..."
                      name="search"
                      onChange={handleSearch}
                      InputProps={{
                        endAdornment: <Search />,
                      }}
                    />
                  </Grid>
                   <Grid
                    item
                    xs={4}
                    sm={4}
                    md={2}
                    lg={2}
                    style={{ position: "relative" }}
                  >
                    <div style={{ maxWidth: "95%" }}>
                      <CustomButton
                        btnText="Search"
                        height="58px"
                        marginTop="20px"
                      />
                    </div>
                   {/*  <Close
                      style={{
                        position: "absolute",
                        bottom: "75px",
                        left: "85px",
                        cursor: "pointer",
                      }}
                      className={classes.searchIcon}
                      onClick={() => setShowSearch(!showSearch)}
                    /> */}
                  </Grid>


            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Grid container>
              {filteredData.length > 0 &&
                filteredData?.map((item, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      className={classes.blogCard}
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                        padding: isMobile && "10px 10px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <BlogCard data={item} />
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={4}>
            <BlogSidebar />
          </Grid> */}
        </Grid>
        {/*

      <Grid item xs={12} >
        <HomeSection3 />
      </Grid>

      <Grid item xs={12} >
        <HomeSection4 />
      </Grid> */}
      </Grid>
    </Layout>
  );
}

// export default IndexPage

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "center",
    width: "100%",
    fontFamily: theme.palette.fontFamily.Reef,
    fontWeight: 900,
    fontSize: "36px",
    lineHeight: "42.19px",

    fontStyle: "normal",
    color: "#000",
  },
  searchIcon: {
    color: "#09A9BB",
    cursor: "pointer",
  },
  searchField: {
    "& .MuiInputBase-root": {
      backgroundColor: "rgba(9, 169, 187, 0.1)",
      color: "rgba(124, 124, 124, 0.25)",
      fontFamily: theme.palette.fontFamily.Reef,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px 30px",
      height: "58px",
      alignSelf: "center",
      position: "static",
      marginBottom: "20px",
      width: "100%",
      marginTop: "20px",
      borderRadius: "10px",
      boxShadow: "inset 0px 6px 6px rgba(0, 65, 72, 0.05)",
    },
    "& .MuiBadge-colorPrimary": {
      backgroundColor: theme.palette.secondary.light,
    },

    "& .MuiInput-underline:before": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline::after": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid #FBF1E0",
    },
  },
  btn: {
    background: theme.palette.primary.dark,
    borderRadius: "7px",
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    width: "100%",
    height: "58px",
    lineHeight: "28px",
    color: "#FBFBFF",
    textTransform: "none",
    marginTop: "50px",
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },

  blogCard: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
}));

export const query = graphql`
  query MyQuery {
    allStrapiBlogs(sort: {fields: published_at, order: DESC}) {
      edges {
        node {
          content
          description
          author
          strapiId
          slug
          title
          readingTime
          published_at
          seoImageAlternativeText
          imageAlternativeText
          image {
            publicURL
          }
        }
      }
    }

    strapiBlogPage {
      title
      seo_description
      seo_title
      seo_tags
      heroImage {
        publicURL
      }
    }
  }
`;
