import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { useMediaQuery } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "gatsby";

// import Moment from "react-moment"
import Truncate from "react-truncate";
import TruncateText from "react-text-truncate";
export default function BlogCard({ data }) {

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const medium = useMediaQuery('(min-width:1000px)');
  const large = useMediaQuery('(min-width:1100px)');
  const [isLoading, setIsLoading] = useState(true);
  const wid = window.innerWidth;

  return (
    <Link to={`/blog/${data?.slug}`} style={{ textDecoration: "none" }}>
      <Card className={classes.root}   style={{ height:"100%"}} >

          {data?.image?.publicURL ? (
            //   <CardMedia
            //     component="img"
            //     alt="thumbnail"
            //     className={classes.media}
            //     image={data?.image?.publicURL}
            //     title="Course Thumbnail"
            //   />
            // ) : (
            //   <Skeleton
            //     animation="wave"
            //     variant="rect"
            //     className={classes.media}
            //   />

            <div className={classes.media} /*  style={{ flex: 1.3344 }} */ >
              <img
                // src={`${RELATE_APP_BASE_URL}${data?.image}`}
                src={data?.image?.publicURL}
                style={{
                  display: isLoading ? "none" : "block",
                }}
                onLoad={() => {
                  setIsLoading(false);
                }}
                onError={(err) => {
                  setIsLoading(false);
                }}
                className={classes.mediaImage}
              alt={data?.imageAlternativeText}
              style={{
                height:  "100%",
                maxHeight:  "100%",
              }}
              loading="lazy"
              />
            </div>
          ) : (
            <Skeleton
              animation="wave"
              variant="rect"
              className={classes.media}
            />
          )}

          {/* end */}

          <CardContent
            style={{
              backgroundColor: "#FFFF",
            padding:"8px",
            }}
          >
            <Typography
              gutterBottom
              variant="h6"
              component="h6"
              className={classes.heading}
              style={{
                fontSize: isMobile && "20px",
                textTransform: "capitalize",
              }}
            >
              {data?.author}
            </Typography>

            <Typography
              className={classes.description}
              variant="h2"
                   style={{
                fontSize: isMobile && "15px",
                textTransform: "capitalize",
                // width: "100%",
              }}
            >
          {/* {data?.title} */}
              <TruncateText line={2} text={data?.title} truncateText="..."   element="span"/* ellipsis={<span>...</span>} */ className={classes.description}
                 /*  {`   ${data?.title}  `} */
                />
            </Typography>
          </CardContent>

      </Card>
    </Link>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: "11px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
    background: "#FFF",

  },
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    color: "#676767",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  date: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#000",
    marginBottom: "10px",
  },
  description: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#000",
   /*  overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical" */
  },

  media: {
    flex: 1.3344,
    height: "0",
    position: "relative",
    paddingTop: "54.25%",
    width: "100%",
    BorderRadiusTopRight: "11px",
    BorderRadiusTopLeft: "11px",
   // background: "#FBFBFB",
    background: "#C4C4C4",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    display: "flex",
    alignItems: "center",
  },
  mediaImage: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    aspectRatio: 16 / 9,
  },
}));
